import "./protocolNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProtocol } from "../../contexts/protocol";
import { useCartridge } from "../../contexts/cartridge";
// api
import { queryJsonArray, deleteRowById, readJson, uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import Switch from "devextreme-react/switch";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";
import { alert, confirm } from "devextreme/ui/dialog";

export default function ProtocolNew() {
  const { user } = useAuth();
  const protocolContext = useProtocol();
  const cartridgeContext = useCartridge();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("New Protocol");
  const [protocolNameList, setProtocolNameList] = useState<Array<string>>([]);
  const [protocolName, setProtocolName] = useState("");
  const [isModelCategorySelectBoxHidden, setIsModelCategorySelectBoxHidden] = useState(false);
  const [isModelCategoryTextBoxHidden, setIsModelCategoryTextBoxHidden] = useState(true);
  const [modelCategory, setModelCategory] = useState("");
  const [modelCategoryList, setModelCategoryList] = useState<Array<string>>([]);
  const [cartridge, setCartridge] = useState("");
  const [cartridgeList, setCartridgeList] = useState<Array<string>>([]);
  const [isCartridgeSelectBoxHidden, setIsCartridgeSelectBoxHidden] = useState(false);
  const [isCartridgeTextBoxHidden, setIsCartridgeTextBoxHidden] = useState(true);
  const [outflow, setOutflow] = useState("");
  const [mixingRatio, setMixingRatio] = useState("");

  const [freq, setFreq] = useState("");
  const [voltage, setVoltage] = useState("");
  const [isVoltageReadOnly, setIsVoltageReadOnly] = useState(true);
  const [duty, setDuty] = useState("");
  const [coolerSwitch, setCoolerSwitch] = useState(true);
  const [maxVolumeSwitch, setMaxVolumeSwitch] = useState(true);
  const [harvestVolume, setHarvestVolume] = useState("0");
  const [isHarvestVolumeReadOnly, setIsHarvestVolumeReadOnly] = useState(true);
  const [numberOfUse, setNumberOfUse] = useState("0");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let userId: string = "";
    if (user) {
      userId = user.id;
    }
    //if (cartridgeContext) {
    //setCartridgeList(cartridgeContext.codeList);
    //}

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      initializeList();
    }

    // SelectBox의 값을 설정할 때에는 dataSource의 list 안에 있는 값만 적용된다. 다른 값을 설정하면 화면에는 선택 안된 걸로 표시된다.
    if (0 < protocolContext.id.length) {
      // edit
      setPageTitle("Edit Protocol");
      setProtocolName(protocolContext.id);
      setModelCategory(protocolContext.modelCategory);
      setIsModelCategorySelectBoxHidden(true);
      setIsModelCategoryTextBoxHidden(false);
      setCartridge(protocolContext.cartridge);
      setIsCartridgeSelectBoxHidden(true);
      setIsCartridgeTextBoxHidden(false);
      setOutflow(protocolContext.outflow);
      setMixingRatio(protocolContext.mixingRatio);
      setFreq(protocolContext.freq);
      setVoltage(protocolContext.voltage);
      setIsVoltageReadOnly(true);
      setDuty(protocolContext.duty);
      let coolerF: number = U.floatFromString(protocolContext.cooler, 0);
      if (coolerF == 0) setCoolerSwitch(false);
      else setCoolerSwitch(true);
      let maxVolumeN: number = U.intFromString(protocolContext.maxVolume, 0);
      if (maxVolumeN == 0) {
        setMaxVolumeSwitch(false);
        setIsHarvestVolumeReadOnly(false);
      } else {
        setMaxVolumeSwitch(true);
        setIsHarvestVolumeReadOnly(true);
      }
      setHarvestVolume(protocolContext.harvestVolume);
      setNumberOfUse(protocolContext.numberOfUse);
    } else {
      setPageTitle("New Protocol");
      setIsModelCategorySelectBoxHidden(false);
      setIsModelCategoryTextBoxHidden(true);
      setIsCartridgeSelectBoxHidden(false);
      setIsCartridgeTextBoxHidden(true);
      setMixingRatio("2");
      setFreq("10");
      setVoltage("1200");
      setIsVoltageReadOnly(false);
      setDuty("50");
      setCoolerSwitch(true);
      setMaxVolumeSwitch(true);
      setIsHarvestVolumeReadOnly(true);
      setHarvestVolume("0");
      setNumberOfUse("0");
    }
  }, []);

  function initializeList() {
    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let mcList = new Array<string>();
    for (let i = 0; i < cartridgeContext.modelCategoryList.length; i++) {
      let newMc = cartridgeContext.modelCategoryList[i];
      let isSameName: boolean = false;
      for (let j = 0; j < mcList.length; j++) {
        if (newMc === mcList[j]) {
          isSameName = true;
          break;
        }
      }
      if (isSameName == false) mcList.push(newMc);
    }
    setModelCategoryList(mcList);

    let query = "SELECT id FROM protocol";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/project/query", query).then((jsonData: Array<Object>) => {
      let nameList = [];
      for (let i = 0; i < jsonData.length; i++) {
        nameList.push(U.stringValueFromJson(jsonData[i], "id"));
      }
      setProtocolNameList(nameList);
    });
  }

  function updateProtocolName(currentCartridge: string, currentVoltage: string) {
    // FCC-2310-1000V-2409006
    let currentVoltageF: number = U.floatFromString(currentVoltage, 0);
    let voltagePart: string = `${currentVoltageF}V`;

    let date = new Date();
    let year: number = date.getFullYear(); // 2024
    let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
    let strYear: string = year.toString();
    let yy: string = strYear.substring(2, 4);
    let strMonth: string = month.toString();
    let mm: string = "";
    if (strMonth.length == 1) mm = "0";
    mm += strMonth;
    let yymm: string = yy + mm;

    let maxIndex: number = 0;
    for (let i = 0; i < protocolNameList.length; i++) {
      let listName: string = protocolNameList[i];
      if (listName.length < 9) continue;
      let listYymm: string = listName.substring(listName.length - 7, listName.length - 3);
      let listIndex: string = listName.substring(listName.length - 3, listName.length);
      if (yymm === listYymm) {
        let index: number = U.intFromString(listIndex, 0);
        if (maxIndex < index) maxIndex = index;
      }
    }
    let strNewIndex: string = "";
    let newIndex: number = maxIndex + 1;
    if (newIndex < 10) strNewIndex = "00" + newIndex.toString();
    else {
      if (newIndex < 100) strNewIndex = "0" + newIndex.toString();
      else strNewIndex = newIndex.toString();
    }
    if (strNewIndex.length > 3) strNewIndex = "999";
    let newName: string = `${currentCartridge}-${voltagePart}-${yymm}${strNewIndex}`;
    setProtocolName(newName);
  }

  function onModelCategorySelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setModelCategory(str);
    setCartridge("");
    setOutflow("");
    setMixingRatio("");
    setFreq("");
    setDuty("");
    if (!cartridgeContext) return;
    let catList = new Array<string>();
    for (let i = 0; i < cartridgeContext.modelCategoryList.length; i++) {
      if (str === cartridgeContext.modelCategoryList[i]) {
        catList.push(cartridgeContext.catNoList[i]);
      }
    }
    setCartridgeList(catList);
    updateProtocolName("", voltage);
  }

  function onCartridgeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setCartridge(str);
    let newOutflow: string = "";
    for (let i = 0; i < cartridgeContext.catNoList.length; i++) {
      if (cartridgeContext.catNoList[i] === str) {
        newOutflow = cartridgeContext.outflowList[i].toString();
        break;
      }
    }
    setOutflow(newOutflow);
    setMixingRatio("2");
    setFreq("10");
    setDuty("50");
    updateProtocolName(str, voltage);
  }
  function onHarvestVolumeTextBoxValueChanged(e: string) {
    setHarvestVolume(e);
  }

  function onOutflowTextBoxValueChanged(e: string) {
    setOutflow(e);
  }
  function onMixingRatioTextBoxValueChanged(e: string) {
    setMixingRatio(e);
  }
  function onFreqTextBoxValueChanged(e: string) {
    setFreq(e);
  }
  function onVoltageTextBoxValueChanged(e: string) {
    setVoltage(e);
    updateProtocolName(cartridge, e);
  }
  function onDutyTextBoxValueChanged(e: string) {
    setDuty(e);
  }
  function onCoolerSwitchValueChanged(e: boolean) {
    setCoolerSwitch(e);
  }
  function onMaxVolumeSwitchValueChanged(e: boolean) {
    setMaxVolumeSwitch(e);
    if (e === true) {
      setIsHarvestVolumeReadOnly(true);
      setHarvestVolume("0");
    } else {
      setIsHarvestVolumeReadOnly(false);
    }
  }

  async function onUploadButtonClicked() {
    if (protocolName.length === 0) {
      alert("Enter protocol name!", "Error");
      return;
    }
    if (cartridge.length === 0) {
      alert("Select cartridge!", "Error");
      return;
    }

    let outflowF: number = U.floatFromString(outflow, 0);
    let mixingRatioF: number = U.floatFromString(mixingRatio, 0);
    let freqF: number = U.floatFromString(freq, 0);
    if (freqF !== 1 && freqF !== 2 && freqF !== 3 && freqF !== 4 && freqF !== 5 && freqF !== 6 && freqF !== 7 && freqF !== 8 && freqF !== 9 && freqF !== 10) {
      alert("Enter integer value between 1 ~ 10 for frequency!", "Error");
      return;
    }
    let voltageF: number = U.floatFromString(voltage, -1);
    if (voltageF < 0) {
      alert("Enter proper value for voltage!", "Error");
      return;
    }

    let dutyF: number = U.floatFromString(duty, 0);
    let coolerF: number = 100;
    if (coolerSwitch === false) coolerF = 0;
    let harvestVolumeF: number = U.floatFromString(harvestVolume, 0);
    let maxVolumeN: number = 1;
    if (maxVolumeSwitch === false) maxVolumeN = 0;
    else harvestVolumeF = 0;
    let numberOfUseF: number = U.floatFromString(numberOfUse, 0);

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let protocolName2: string = U.replaceBrace(protocolName);

    let obj: Object = {
      id: protocolName2,
      modelCategory: modelCategory,
      cartridge: cartridge,
      outflow: outflowF,
      mixingRatio: mixingRatioF,
      freq: freqF,
      voltage: voltageF,
      duty: dutyF,
      cooler: coolerF,
      maxVolume: maxVolumeN,
      harvestVolume: harvestVolumeF,
      numberOfUse: numberOfUseF,
    };

    await uploadJson("/project/uploadprotocol", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        let res = confirm("Same id exists! Do you want to overwrite?", "Warning");
        res.then((dialogResult) => {
          if (dialogResult) {
            // Yes
            uploadJsonAndAlert("/project/overwriteprotocol", obj);
            navigate(-1);
          } else return;
        });
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Protocol Name" value={protocolName} readOnly={true} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"} hidden={isModelCategorySelectBoxHidden}>
              <SelectBox
                label="Model Category"
                dataSource={modelCategoryList}
                value={modelCategory}
                width={300}
                onSelectionChanged={onModelCategorySelectionChanged}
              />
            </div>

            <div className={"flex-item2"} hidden={isModelCategoryTextBoxHidden}>
              <TextBox label="Model Category" value={modelCategory} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"} hidden={isCartridgeSelectBoxHidden}>
              <SelectBox label="Cartridge" dataSource={cartridgeList} value={cartridge} width={300} onSelectionChanged={onCartridgeSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isCartridgeTextBoxHidden}>
              <TextBox label="Cartridge" value={cartridge} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Outflow (mL/h)"
                value={outflow}
                readOnly={true}
                valueChangeEvent="keyup"
                onValueChange={onOutflowTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Mixing Ratio (Mat/Cell)"
                value={mixingRatio}
                readOnly={true}
                valueChangeEvent="keyup"
                onValueChange={onMixingRatioTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Frequency (kHz)"
                value={freq}
                readOnly={true}
                valueChangeEvent="keyup"
                onValueChange={onFreqTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Voltage (V)"
                value={voltage}
                readOnly={isVoltageReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onVoltageTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Duty (%)"
                value={duty}
                readOnly={true}
                valueChangeEvent="keyup"
                onValueChange={onDutyTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Cooler</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={coolerSwitch} onValueChange={onCoolerSwitchValueChanged} width={40} />
              </div>
            </div>

            <div className={"flex-containerH3"}>
              <div className={"flex-containerTitle"}>
                <p className={"text-subtitle"}>Max Volume</p>
              </div>
              <div className={"flex-switch1"}>
                <Switch value={maxVolumeSwitch} onValueChange={onMaxVolumeSwitchValueChanged} width={40} />
              </div>
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Harvest Volume (mL)"
                value={harvestVolume}
                readOnly={isHarvestVolumeReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onHarvestVolumeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Number of Use" value={numberOfUse} readOnly={true} valueChangeEvent="keyup" width={300} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="success" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
