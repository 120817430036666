import { createContext, useContext } from "react";
export type ProjectContextType = {
  id: string;
  status: string;
  manager: string;
  modelCategory: string;
  protocol: string;
  cellName: string;
  cellType: string;
  cellSize: string;
  cellBuffer: string;
  cellConc: string;
  matName: string;
  matType: string;
  matMolWeight: string;
  matBuffer: string;
  matConc: string;
  cartridge: string;
  outflow: string;
  mixingRatio: string;
  freq: string;
  voltage: string;
  duty: string;
  cooler: string;
  maxVolume: string;
  harvestVolume: string;
  startDate: Date;
  numberOfUse: string;
  used_at: Date;
};
const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType);
const useProject = () => useContext(ProjectContext);
export { useProject };
